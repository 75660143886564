<template>
  <div class="Site PageBox">
    <van-nav-bar
            fixed
            :border="false"
            :title="$t('bindAccount.default[0]')"
            left-arrow
            @click-left="$router.push('/')"
    />
    <div class="ScrollBox">
          <van-form @submit="onSubmit">
            <div class="box">
                <van-field
                        v-model="BankInfo.country"
                        :label="$t('bindAccount.fields[0]')"
                        :placeholder="$t('bindAccount.placeholder[0]')"
                />
                <van-field
                        v-model="BankInfo.swift"
                        :label="$t('bindAccount.fields[1]')"
                        :placeholder="$t('bindAccount.placeholder[1]')"
                />
<!--                <van-field-->
<!--                        v-model="BankInfo.upi"-->
<!--                        :label="$t('bindAccount.fields[2]')"-->
<!--                        :placeholder="$t('bindAccount.placeholder[2]')"-->
<!--                />-->
                <van-field
                        v-model="BankInfo.bank_name"
                        :label="$t('bindAccount.fields[3]')"
                        :placeholder="$t('bindAccount.placeholder[3]')"
                />
                <van-field
                        v-model="BankInfo.iban"
                        :label="$t('bindAccount.fields[4]')"
                        :placeholder="$t('bindAccount.placeholder[4]')"
                />
                <van-field
                        v-model="BankInfo.card_no"
                        :label="$t('bindAccount.fields[5]')"
                        :placeholder="$t('bindAccount.placeholder[5]')"
                />
                <van-field
                        v-model="BankInfo.name"
                        :label="$t('bindAccount.fields[6]')"
                        :placeholder="$t('bindAccount.placeholder[6]')"
                />
                <van-field
                        v-model="BankInfo.surname"
                        :label="$t('bindAccount.fields[7]')"
                        :placeholder="$t('bindAccount.placeholder[7]')"
                />
                <van-field
                        v-model="BankInfo.address"
                        :label="$t('bindAccount.fields[8]')"
                        :placeholder="$t('bindAccount.placeholder[8]')"
                />
                <van-field
                        v-model="BankInfo.post_code"
                        :label="$t('bindAccount.fields[9]')"
                        :placeholder="$t('bindAccount.placeholder[9]')"
                />
                <van-field
                        v-model="BankInfo.city"
                        :label="$t('bindAccount.fields[10]')"
                        :placeholder="$t('bindAccount.placeholder[10]')"
                />
                <van-field
                        v-model="BankInfo.province"
                        :label="$t('bindAccount.fields[11]')"
                        :placeholder="$t('bindAccount.placeholder[11]')"
                />
                  <van-field
                          v-model="BankInfo.phone"
                          :label="$t('bindAccount.fields[12]')"
                          :placeholder="$t('bindAccount.placeholder[12]')"
                  />
            </div>
            <div v-show="!canEdit" style="margin-left: 20px;margin-top: 20px">{{$t('busAuth2[4]')}}</div>
            <div style="text-align: center;margin-top: 20px" v-show="canEdit">
              <p class="btn" @click="onSubmit">{{ $t('bindAccount.default[1]') }}</p>
            </div>
          </van-form>
    </div>

  </div>
</template>

<script>
export default {
  name: 'BindAccount',
  components: {

  },
  props: [],
  data() {
    return {
      tabsActive: 0,
      weixinAcc: '',
      showUsdtType: false,
      columns : ['ERC20', 'TRC20', 'OMNI'],
      BankInfo:{},
      douyinImg: [],
      kuaishouImg: [],
      accountArr: [],
      canEdit:true,
      tabsList: [],
    }
  },
  computed: {

  },
  watch: {

  },
  created() {
    this.$Model.GetUserInfo()

    this.BankInfo.coin_type = this.columns[0];
    this.$Model.GetBankCardList(data=>{
      if (data.code==1&&data['data'].length>0){
        this.BankInfo = data['data'][0];
        this.canEdit = false;
      }
      }
    );
  },
  mounted() {

  },
  activated() {

  },
  destroyed() {

  },
  methods: {
    onSubmit() {
      this.$Model.AddBankCard(this.BankInfo,data=>{
          debugger
         var msg = "";
         if (data.code==1){
             msg = this.$t('common4[1]');
         }else{
             msg = data.code_dec;
         }
          this.$Dialog.Toast(msg);
      });
    },
    onChangeType(obj,value){
      this.BankInfo.coin_type = value;
      this.showUsdtType = false;
    },
  }
}
</script>
<style scoped>
    .PageBox {
        color: #cccccc;
        background-color: #13171A;
    }
    .PageBox .box {
        width: 95%;
        padding: 10px 0px 10px 10px;
        font-size: 19px;
        margin-top: 15px;
        border-radius: 10px;
        margin-left: 10px;
        background-color: #191C23;
        color: #cccccc;
    }
    .van-nav-bar {
        background-color: #191C23;
    }

    .van-nav-bar>>>.van-nav-bar__title{
        color:#cccccc;
    }

    .PageBox .van-cell{
        background-color: #191C23;
    }

    .van-cell>>>.van-cell__title{
        font-size: 18px;
        color:#cccccc;
    }
    .van-uploader>>>.van-uploader__upload,.van-uploader>>>.van-uploader__preview-image,.van-uploader>>>.van-uploader__preview{
        margin: 0;
        background-color: #f7f8fa;
        border-radius: 8px;
    }

    .van-field{
        font-weight: bold;
    }

    .btn {
        width: 85%;
        padding: 10px 50px;
        border-radius: 20px;
        background-color: #0076FA;
        color: #fff;
        font-size: 18px;
        text-align: center;
        margin: 15px 30px 30px;
    }
</style>
